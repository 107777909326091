import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { API_URL_GRANTS as API_URL, setObjToLS } from "../../utils/helpers";

import Radios from "../../components/Radios";
import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Enter = ({ cares }) => {
	const [names, setNames] = useState({});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});

	const formElement = useRef();
	const navigate = useNavigate();

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=entry`);

		let data = new FormData(formElement.current);
		data.append("entry", names.entry ? names.entry : "");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: data,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setObjToLS("grants", {
					token: json.token,
					id: json.auth.id,
					email: json.auth.email,
				});
				navigate("/");
			} else if (json && json.resp === 2) {
				setMsg({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<h2 style={{ marginBottom: "5px" }}>
				2025 Online Grant Application Questions
			</h2>

			<div className="grants container">
				<div className="grants left">
					<h6>
						Mission: Children’s Healthcare Charity is a 501(c)(3) organization
						established in 2006 and is dedicated to providing financial
						resources to organizations that enhance the health and welfare of
						children in Palm Beach and surrounding counties.
					</h6>

					<h3>Grant Guidelines</h3>

					<ul>
						<li>
							Recipient must be a 501(c)(3) non-profit organization as defined
							by the Internal Revenue Service.
						</li>
						<li>
							Recipients should be in existence for at least 3 years. No grants
							will be given to individuals.
						</li>
						<li>
							Recipients are required to be based or operating in the
							geographical areas defined in the mission statement.
						</li>

						<li>
							The grant request should relate to the health and welfare of
							children.
						</li>
						<li>Grants are generally made in May of each calendar year.</li>
						<li>
							<span style={{ color: "var(--error)", fontWeight: "400" }}>
								Please save or export from Word as plain text before pasting
								your content into the grant form.
							</span>
						</li>
						<li>
							<span style={{ fontWeight: "bold" }}>
								APPLICATION DUE ON MARCH 31, 2025
							</span>
						</li>
					</ul>

					<p>
						Any questions regarding grants may be directed to{" "}
						<a href="mailto:director@childrenshealthcarecharity.org">
							director@childrenshealthcarecharity.org
						</a>
						.
					</p>
				</div>
				<div className="grants right">
					<h3>First Visit or Returning?</h3>

					<form
						method="post"
						action="/"
						onSubmit={(e) => handleSubmit(e)}
						ref={formElement}
					>
						<div>
							<Radios
								name="entry"
								hdg=""
								update={handleUpdate}
								data={[
									{
										value: "first",
										label:
											"This is my first time starting the application for 2025.",
										checked: names.entry === "first" ? true : false,
									},
									{
										value: "returning",
										label:
											"I am returning to update my application for 2025 that I previously started.",
										checked: names.entry === "returning" ? true : false,
									},
								]}
							/>
						</div>

						<div className="one-full">
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" ? "hidden" : "submit-container"
								}
							>
								<Submit
									name="Continue"
									icon={faChevronCircleRight}
									cares={cares}
								/>
							</div>
						</div>

						<p>We will not share your email address with third-parties.</p>
						<p className="spacer" />
					</form>
				</div>
			</div>
		</>
	);
};

export default Enter;
